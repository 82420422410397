<template>
  <div style="height: 100vh; overflow:hidden;">
    <!-- <div class="loading" v-if="loaded">
      <div class="loadbox"><img src="@/assets/images/loading.gif" /> 页面加载中...</div>
    </div> -->
    <div class="back"></div>
    <div id="map"></div>
    <!-- 头部信息 -->
    <div class="head" style="z-index:1;background-size: 100% 100%;">
      <div class="weather USA">
        <span class="time" id="showTime">{{ CN_TIME }}</span>
      </div>
      <!-- <h1>力宾销售数据可视化</h1> -->
      <h1 class="leobon">LEOBON Sales Data Visualization</h1>
      <div class="weather CN">
        <span class="time" id="showTime">{{ US_TIME }}</span>
      </div>
    </div>

    <div class="mainbox">
      <ul class="clearfix rela">
        <!-- <div id="map"></div> -->
        <!-- 销量排名前茅 -->
        <li>
          <div class="boxall fixed_height">
            <!-- <div class="alltitle">销量排名前茅</div> -->
            <div id="container" class="chart_height"></div>
          </div>
        </li>

        <!-- 部门排行 -->
        <li>
          <div class="boxall fixed_height">
            <!-- <div class="alltitle">部门排行</div> -->
            <div class="navboxall">
              <div class="wraptit flex row-around">
                <span class="title">部门</span>
                <span class="title">订单总金额</span>
              </div>
              <div class="wrap">
                <ul>
                  <!-- 部门排行 -->
                  <li v-for="(item, index) in deptArr.slice(0 ,3)" :key="index">
                    <p class="flex row-around" v-if="item.momeny != 0">
                      <span class="title">{{ item.dept }}</span
                      ><span class="title">${{ item.momeny }}</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="boxall groupAll">
            <!-- <div class="alltitle">小组排行</div> -->
            <div class="flex-col row-center">
              <div
                class="flex row-between"
                style="border-bottom: 1px dotted rgba(255, 255, 255, .15);padding: 0 0 10px 0;margin-bottom:10px;"
              >
                <span class="title" style="color:rgba(255, 255, 255, .6);"
                  >小组</span
                >
                <span class="title" style="color:rgba(255, 255, 255, .6);"
                  >订单总金额</span
                >
              </div>
              <div class="box">
                <!-- <vue-seamless-scroll
                  :data="groupArr"
                  :class-option="classOption"
                > -->
                <ul>
                  <li v-for="item in groupArr" :key="item.group">
                    <div
                      class="flex row-between listItem"
                      v-if="item.momeny != 0"
                    >
                      <span class="group">{{ item.group }}</span>
                      <span class="group">${{ item.momeny }}</span>
                    </div>
                  </li>
                </ul>
                <!-- </vue-seamless-scroll> -->
              </div>
            </div>
          </div>
        </li>

        <li class="absolute footer_money">
          <div class="boxall totals footer_hight">
            <div class="navboxall">
              <div class="sycm">
                <ul class="clearfix flex row-center col-center">
                  <li class="flex-col row-center col-center footer_position">
                    <h2>${{ totals }}</h2>
                    <!-- <span class="allMoney">${{ totals }}</span> -->
                    <span class="title">总金额</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <!-- 金额占比和数量 -->
        <!-- <li>
          <div class="boxall" style="height:545px;">
            <div class="navboxall">
              <div class="sycm">
                <ul class="clearfix flex row-center col-center">
                  <li
                    class="flex-col row-center col-center "
                    style="height:545px;"
                  >
                    <h2>${{ totals }}</h2>
                    <span>总金额</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { Bar } from "@antv/g2plot";
import { Scene, PointLayer } from "@antv/l7";
import { GaodeMap } from "@antv/l7-maps";
export default {
  data() {
    return {
      loaded: "",
      CN_TIME: "",
      US_TIME: "",
      personCharts: null,
      data: [], // 个人销售列表
      totals: "",
      deptArr: [], // 部门列表
      groupArr: [], // 小组列表
      timer: null, // 定时器
    };
  },

  computed: {
    classOption() {
      return {
        step: 0.5,
        limitMoveNum: this.groupArr.length,
        hoverStop: true,
        autoPlay: true,
      };
    },
  },

  async created() {
    this.getTime();
    await this.init();
    this.$nextTick(() => {
      this.getCharts();
    });
    // this.init1();
    this.get();
  },

  mounted() {
    const scene = new Scene({
      id: "map",
      map: new GaodeMap({
        pitch: 0,
        style: "dark",
        center: [-96.99215001469588, 29.281597225674773],
        zoom: 2.194613775109773,
        maxZoom: 10,
        type: "amap", // 这个type = amap 也要 加上
        token: 'af6e93aa2aa27a2f18716e4d4d94d3db'
      }),
    });
    scene.on("loaded", () => {
      fetch(
        "https://gw.alipayobjects.com/os/basement_prod/337ddbb7-aa3f-4679-ab60-d64359241955.json"
      )
        .then((res) => res.json())
        .then((data) => {
          data.features = data.features.filter((item) => {
            return item.properties.capacity > 1300;
          });
          const pointLayer = new PointLayer({})
            .source(data)
            .shape("circle")
            .size("capacity", [0, 16])
            .color("capacity", [
              "#34B6B7",
              "#4AC5AF",
              "#5FD3A6",
              "#7BE39E",
              "#A1EDB8",
              "#CEF8D6",
            ])
            .active(true)
            .style({
              opacity: 0.5,
              strokeWidth: 0,
            });
          scene.addLayer(pointLayer);
        });
    });

  },

  methods: {
    init() {
      return new Promise((resolve) => {
        // 部门排行
        this.$api.welcome.countByDept().then((res) => {
          this.deptArr = []; // 部门列表
          for (let i in res.data.dept) {
            this.deptArr.push({
              dept: i,
              momeny: res.data.dept[i].all.toFixed(2),
            });
          }
          const list = [];
          this.groupArr = []; // 小组列表
          for (let i in res.data.group) {
            list.push({ group: i, momeny: res.data.group[i].all.toFixed(2) });
          }
          this.groupArr = list;
        });

        // 获取总金额
        this.$api.welcome.totals().then((res) => {
          this.totals = res.data[0];
        });

        // 获取用户汇总信息 TOP3
        this.$api.welcome.countByPerson().then((res) => {
          const personArr = [];
          res.data.sellOrder.map((item) => {
            if (item.all != 0) {
              personArr.push({ name: item.name, momeny: item.all });
            }
            this.data = personArr.slice(0, 4);
          });
          if (this.personCharts) {
            this.personCharts.changeData(this.data); // 更新图表数据源
          }
          resolve();
        });
      });
    },

    get() {
      this.timer = setInterval(() => {
        this.init();
      }, 60000);
    },

    beforeDestroy() {
      clearInterval(this.timer);
    },

    init1() {
      this.$api.welcome.countByDept().then((res) => {
        console.log("获取部门排行", res);
      });
      this.$api.welcome.countByProducts().then((res) => {
        console.log("获取商品排行", res);
      });
      this.$api.welcome.getRecentlyOrder().then((res) => {
        console.log("获取最新订单", res);
      });
      this.$api.welcome.countByPerson().then((res) => {
        console.log("获取用户汇总信息", res);
      });
      this.$api.welcome.countBySeller().then((res) => {
        console.log("获取店铺出单排名", res);
      });
      this.$api.welcome.getSellerInfo().then((res) => {
        console.log("获取店铺业绩", res);
      });
      this.$api.welcome.readFileList().then((res) => {
        console.log("获取轮播图片", res);
      });
      // this.$api.welcome.totals().then((res) => {
      //   this.totals = res.data;
      //   console.log("总数统计", res);
      // });
    },
    getTime() {
      setInterval(() => {
        const Nows = new Date();
        let y = Nows.getFullYear();
        let mt = Nows.getMonth() + 1;
        let day = Nows.getDate();
        let h = Nows.getHours(); //获取时
        let m = Nows.getMinutes(); //获取分
        let s = Nows.getSeconds(); //获取秒
        // 美国时间
        let getTime = new Date().getTime();
        getTime = getTime - 15 * 3600 * 1000;
        getTime = new Date(getTime);
        let us_y = getTime.getFullYear();
        let us_mt = getTime.getMonth() + 1;
        let us_day = getTime.getDate();
        let us_h = getTime.getHours();
        let us_m = getTime.getMinutes();
        let us_s = getTime.getSeconds();
        this.US_TIME =
          us_y +
          "年" +
          us_mt +
          "月" +
          us_day +
          "日" +
          us_h +
          "时" +
          us_m +
          "分" +
          us_s +
          "秒";
        this.CN_TIME =
          y + "年" + mt + "月" + day + "日" + h + "时" + m + "分" + s + "秒";
      }, 1000);
    },
    getCharts() {
      this.personCharts = new Bar("container", {
        data: this.data,
        xField: "momeny",
        yField: "name",
        xAxis: {
          label: null,
          grid: {
            line: {
              style: {
                strokeOpacity: 0,
              },
            },
          },
        },
        yAxis: {
          label: {
            style: {
              fontSize: 30,
            },
          },
          line: null,
        },
        meta: {
          name: {
            alias: "类别",
          },
          momeny: {
            alias: "销售额",
          },
        },
        barStyle: {
          fill: "#4e7385",
        },
        label: {
          // 可手动配置 label 数据标签位置
          position: "middle", // 'left', 'middle', 'right'
          offset: 4,
          style: {
            fill: "#fff",
            fontSize: 20,
          },
          formatter(val) {
            if (val.momeny) {
              return "$" + val.momeny.toFixed(2);
            } else {
              return "";
            }
          },
        },
        minBarWidth: 50,
        maxBarWidth: 50,
      });
      this.personCharts.render();
    },

  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/css/comon.css");

@media screen and (min-width: 576px) and (max-width: 1919px) {
  .title {
    font-size: 1rem;
  }

  .leobon {
    font-size: 2rem;
  }

  .time {
    font-size: 1.5em;
  }

  .footer_money {
    width: 70%;
    top: 410px;
  }

  .footer_hight {
    height: calc(100vh - 535px);
  }

  .fixed_height {
    height: 400px;
  }

  .footer_position {
    height: calc(100vh - 555px);
  }

  .chart_height {
    height: 300px;
  }

  .group {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.6);
  }

  .sycm li h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1920px) and (max-width: 99999px) {
  .footer_money {
    width: 70%;
    top: 560px;
  }

  .title {
    font-size: 1.8rem;
  }

  .fixed_height {
    height: 545px;
  }

  .footer_position {
    padding-top: 50px;
  }

  .group {
    font-size: 1.8rem;
    color: rgba(255, 255, 255, 0.6);
  }
  //#map {
  //  position: absolute;
  //  left: 0px;
  //  top: 0px;
  //  height: 100vh;
  //  width: 100vw;
  //  background-image: url("../assets/images/map.jpg");
  //  background-repeat: no-repeat;
  //  background-size: 100% 100%;
  //  background-attachment:fixed;
  //  background-position:center;
  //}
}
</style>
