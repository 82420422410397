<template>
  <div class="banner">
    <div class="title" style="padding:1rem 0">LEOBON Sales Data Visualization</div>
    <van-tabs v-model="active" type="card" color="#7DAEE1" background="#1A3770" swipeable>
      <van-tab>
        <template #title>
          <span :style="active == 0 ? 'color:black;': 'color:#fff;'">个人排行</span>
        </template>
        <div style="height:calc(100vh - 75px)">
          <!-- <div id="container" class="chart"></div> -->
          <div class="flex-col col-center" style="padding-top:6rem;">
            <img style="width:80%;height:80%;" :src="require('../assets/images/jianpai.png')" />
            <div class="flex-col col-center relative">
              <img style="width:90%;height:90%;" :src="require('../assets/images/颁奖台.png')" />
              <div class="flex-col col-center absolute sale_one" v-if="data.length">
                <div style="color:#fff;">{{ data[0].name }}</div>
                <div style="color:#fff;">${{ data[0].momeny }}</div>
              </div>
              <div class="flex-col col-center absolute sale_two" v-if="data.length">
                  <div style="color:#fff;">{{ data[1].name }}</div>
                  <div style="color:#fff;">${{ data[1].momeny }}</div>
                </div>
              <div class="flex-col col-center absolute sale_three" v-if="data.length">
                <div style="color:#fff;">{{ data[2].name }}</div>
                <div style="color:#fff;">${{ data[2].momeny }}</div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab>
        <template #title>
          <span :style="active == 1 ? 'color:black' : 'color:#fff'">小组排行</span>
        </template>
        <div style="min-height:calc(100vh - 75px)">
          <div v-for="item in groupArr" :key="item.group">
            <div class="flex" v-if="item.momeny != 0">
              <span class="title">{{ item.group }}</span
              ><span class="title">${{ item.momeny }}</span>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab>
        <template #title>
          <span :style="active == 2 ? 'color:black' : 'color:#fff'">部门排行</span>
        </template>
        <div style="height:calc(100vh - 75px)">
          <div v-for="(item, index) in deptArr.slice(0 ,3)" :key="index">
            <div class="flex" v-if="item.momeny != 0">
              <span class="title">{{ item.dept }}</span
              ><span class="title">${{ item.momeny }}</span>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab>
        <template #title>
          <span :style="active == 3 ? 'color:black' : 'color:#fff'">今日总额</span>
        </template>
        <div style="height:calc(100vh - 75px)">
          <div style="padding:2rem 0 0 0;">
            <div style="text-align:center;font-weight:bold;color: #fff;">CN: {{ CN_TIME }}</div>
            <div style="text-align:center;margin-top:2rem;font-weight:bold;color: #fff;">
              US:{{ US_TIME }}
            </div>
          </div>
          <div class="allMoney">${{ totals }}</div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Bar } from "@antv/g2plot";

export default {
  data() {
    return {
      active: 0,
      CN_TIME: "",
      US_TIME: "",
      timer: null,
      personCharts: null,
      data: [], // 个人销售列表
      totals: "",
      deptArr: [], // 部门列表
      groupArr: [], // 小组列表
      timer: null, // 定时器
    };
  },

  async created() {
    this.getTime();
    await this.init();
    // this.$nextTick(() => {
    //   this.getCharts();
    // });
    this.get();
  },

  methods: {
    init() {
      return new Promise((resolve) => {
        // 部门排行
        this.$api.welcome.countByDept().then((res) => {
          this.deptArr = []; // 部门列表
          for (let i in res.data.dept) {
            this.deptArr.push({
              dept: i,
              momeny: res.data.dept[i].all.toFixed(2),
            });
          }
          const list = [];
          this.groupArr = []; // 小组列表
          for (let i in res.data.group) {
            list.push({ group: i, momeny: res.data.group[i].all.toFixed(2) });
          }
          this.groupArr = list;
        });

        // 获取总金额
        this.$api.welcome.totals().then((res) => {
          this.totals = res.data[0];
        });

        // 获取用户汇总信息 TOP3
        this.$api.welcome.countByPerson().then((res) => {
          const personArr = [];
          res.data.sellOrder.map((item) => {
            personArr.push({ name: item.name, momeny: item.all });
            this.data = personArr.slice(0, 3);
          });
          if (this.personCharts) {
            this.personCharts.changeData(this.data); // 更新图表数据源
          }
          resolve();
        });
      });
    },

    get() {
      this.timer = setInterval(() => {
        this.init();
      }, 60000);
    },

    getTime() {
      setInterval(() => {
        const Nows = new Date();
        let y = Nows.getFullYear();
        let mt = Nows.getMonth() + 1;
        let day = Nows.getDate();
        let h = Nows.getHours(); //获取时
        let m = Nows.getMinutes(); //获取分
        let s = Nows.getSeconds(); //获取秒
        // 美国时间
        let getTime = new Date().getTime();
        getTime = getTime - 15 * 3600 * 1000;
        getTime = new Date(getTime);
        let us_y = getTime.getFullYear();
        let us_mt = getTime.getMonth() + 1;
        let us_day = getTime.getDate();
        let us_h = getTime.getHours();
        let us_m = getTime.getMinutes();
        let us_s = getTime.getSeconds();
        this.US_TIME =
          us_y +
          "年" +
          us_mt +
          "月" +
          us_day +
          "日" +
          us_h +
          "时" +
          us_m +
          "分" +
          us_s +
          "秒";
        this.CN_TIME =
          y + "年" + mt + "月" + day + "日" + h + "时" + m + "分" + s + "秒";
      }, 1000);
    },

    // 获取图表实例
    getCharts() {
      this.personCharts = new Bar("container", {
        data: this.data,
        xField: "momeny",
        yField: "name",
        xAxis: {
          label: null,
          grid: {
            line: {
              style: {
                strokeOpacity: 0,
              },
            },
          },
        },
        yAxis: {
          label: {
            style: {
              fontSize: 20,
              fill: "#fff",
            },
          },
          line: null,
        },
        meta: {
          name: {
            alias: "类别",
          },
          momeny: {
            alias: "销售额",
          },
        },
        barStyle: {
          fill: "#7DAEE1",
        },
        label: {
          // 可手动配置 label 数据标签位置
          position: "middle", // 'left', 'middle', 'right'
          offset: 4,
          style: {
            fill: "#fff",
            fontSize: 20,
          },
          formatter(val) {
            if (val.momeny) {
              return "$" + val.momeny.toFixed(2);
            } else {
              return "";
            }
          },
        },
        minBarWidth: 50,
        maxBarWidth: 50,
      });
      this.personCharts.render();
    },
  },
};
</script>

<style lang="less" scoped>
// /deep/ *,
// body {
//   color: #fff !important;
// }

// /deep/ .van-tabs__nav--line {
//   background: linear-gradient(-90deg, #2045933b, #315fab17, #133a8f42);
// }

/deep/ .van-tab {
  border-radius: 50px;
  border-color: #1A3770!important;
}

/deep/ .van-tabs__nav--card {
  border-radius: 50px;
  border-color: #1A3770!important;
}

/deep/ .van-tab--active {
  background-color: #fff!important;
}
.banner {
  background-image: url("../assets/images/MobileBj.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  // height: 100vh;
  // max-height: 812px;
  .chart {
    // margin-top: 2rem;
    padding: 2rem 1.5rem;
  }
  .allMoney {
    font-weight: bold;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    color: #fff;
  }
  .title {
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    padding: 0.3rem 0;
  }
  .sale_one {
    font-size: 1rem;
    top: 30%;
  }
  .sale_two {
    top: 50%;
    left: 14%;
    font-size: 1rem;
  }
  .sale_three {
    top: 58%;
    right: 15%;
    font-size: 1rem;
  }
}
</style>
